import { writable } from 'svelte/store'
import { isNull } from 'is-what'
import { SEARCH_DATA } from './mock-data'

function writeLoading() {
    const { subscribe, set, update } = writable(false)

    return {
        subscribe,
        set: () => set(true),
        target: v => update(n => v),
        reset: () => set(false),
    }
}

// function writeSearch(o = SEARCH_DATA) {
function writeSearch(o = null) {
    const { subscribe, set, update } = writable(isNull(o) ? o : [...o])

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set(null),
    }
}

function writeShowChart() {
    const { subscribe, set, update } = writable(false)

    return {
        subscribe,
        click: v => update(n => v),
        reset: () => set(false),
    }
}

function writeShowForm() {
    const { subscribe, set, update } = writable(false)

    return {
        subscribe,
        click: v => update(n => v),
        reset: () => set(false),
    }
}

function writeUseDemo() {
    const { subscribe, set, update } = writable(false)

    return {
        subscribe,
        click: () => update(n => true)
    }
}

function writeContact(o = {}) {
        const { subscribe, set, update } = writable({ ...o })
    
        return {
            subscribe,
            set: o => set(o),
            update,
        }
    }

function writeToken(o = '') {
        const { subscribe, set, update } = writable(o)
    
        return {
            subscribe,
            click: v => update(n => v)
        }
    }

function writeClientId(o = null) {
        const { subscribe, set, update } = writable(o)
    
        return {
            subscribe,
            set: o => set(o),
        }
    }

function writeFullReportMessage(o = []) {
        const { subscribe, set, update } = writable(o)
    
        return {
            subscribe,
            click: v => update(n => v),
            reset: () => set([]),
        }
    }

function writeRequestReportMessage(o = '') {
        const { subscribe, set } = writable(o)
    
        return {
            subscribe,
            set: o => set(o),
            reset: () => set(''),
        }
    }

const SECONDS = 3000
function writeTimeToken(
    o = { timeCheck: new Date(Date.now() + SECONDS), isBot: true }
) {
    const { subscribe, set, update } = writable({ ...o })

    return {
        subscribe,
        check: () =>
            update(n => {
                const isBot = new Date() < o.timeCheck
                if(isBot) {
                    return {
                        ...n,
                        timeCheck: new Date(Date.now() + SECONDS)
                    }
                }
                return {
                    ...n,
                    isBot
                }
            }),
        reset: () =>
            set((o = { timeCheck: new Date(Date.now() + SECONDS), isBot: true })),
    }
}

function writeMissingSearchListingData(o = {}) {
    const { subscribe, set, update } = writable({ ...o })

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set({}),
    }
}

export const isLoading = writeLoading()
export const search = writeSearch()
export const showChart = writeShowChart()
export const showForm = writeShowForm()
export const useDemo = writeUseDemo()
export const contact = writeContact()
export const token = writeToken()
export const clientId = writeClientId()
export const fullReportMessage = writeFullReportMessage()
export const requestReportMessage = writeRequestReportMessage()
export const timeToke = writeTimeToken()
export const missingSearchListingData = writeMissingSearchListingData()
