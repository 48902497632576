import { writable } from 'svelte/store'

const _base = {
    lat: -34.397,
    lng: 150.644,
}

function writeReady(o = false) {
    const { subscribe, set } = writable(o)

    return {
        subscribe,
        set: o => set(o)
    }
}

function writeBlur(o = false) {
    const { subscribe, set } = writable(o)

    return {
        subscribe,
        set: o => set(o)
    }
}

function writeClear(o = false) {
    const { subscribe, set } = writable(o)

    return {
        subscribe,
        set: o => set(o)
    }
}

function writePlaceChanged(o = {}) {
    const { subscribe, set, update } = writable({ ...o })

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set({}),
    }
}

function writeMember(o = {}) {
    const { subscribe, set, update } = writable({ ...o })

    return {
        subscribe,
        set: o => set(o),
        update,
        reset: () => set({}),
        // reset: () => set(_base),
    }
}

export const mapsLoaded = writable(false)
export const mapsLoading = writable(false)
export const ready = writeReady()
export const blur = writeBlur()
export const clear = writeClear()
export const placeChanged = writePlaceChanged()
export const member = writeMember(_base)