<svelte:options tag="location-input" />

<script>
    import { onMount } from 'svelte'
    import { placeChanged, member } from './stores.js'
    import { isEmptyObject } from 'is-what'
    import { addressComponents } from '../Util/util'

    let container
    let marker
    let geocoder
    let map
    let zoom = 8
    const controls = {
        mapTypeControl: false,
        streetViewControl: false,
        zoomControl: false,
        scaleControl: false,
        overviewMapControl: false,
        rotateControl: false,
        panControl: false,
        fullscreenControl: false,
    }
    export let center = {}
    const google = window.google

    $: if (!isEmptyObject($placeChanged)) {
        // console.log($placeChanged)
        // console.log(addressComponents($placeChanged?.place?.address_components))
        // $member.lat = $placeChanged.place.geometry.location.lat()
        // $member.lng = $placeChanged.place.geometry.location.lng()
        // $member.location = $placeChanged.place.formatted_address
        $member = {
            info: {
                ...addressComponents($placeChanged?.place?.address_components),
                street: $placeChanged?.place?.name
            },
            lat: $placeChanged.place.geometry.location.lat(),
            lng: $placeChanged.place.geometry.location.lng(),
            location: $placeChanged.place.formatted_address,
        }
        onInit()
    }

    async function onInit() {
        const latLong = { lat: $member.lat, lng: $member.lng }

        map = await new google.maps.Map(container, {
            zoom,
            center: latLong,
            ...controls,
        })

        marker = await new google.maps.Marker({
            position: latLong,
            draggable: true,
            animation: google.maps.Animation.DROP,
            map,
            title: 'Location',
        })

        await google.maps.event.addListener(marker, 'dragend', function () {
            geocodePosition(marker?.getPosition())
        })
    }

    function geocodePositionLanding() {
        if (!$member?.location) {
            const latlng = new google.maps.LatLng($member.lat, $member.lng)
            // This is making the Geocode request
            const geocoder = new google.maps.Geocoder()
            geocoder.geocode({ latLng: latlng }, (results, status) => {
                if (status !== google.maps.GeocoderStatus.OK) {
                    alert(status)
                }
                // This is checking to see if the Geoeode Status is OK before proceeding
                if (status == google.maps.GeocoderStatus.OK) {
                    // console.log(results)
                    const address = results[0].formatted_address
                    // console.log(address)
                    member.set({ ...$member, location: address })
                }
            })
        }
        onInit()
    }

    onMount(() => {
        geocodePositionLanding()
    })

    function geocodePosition(pos) {
        geocoder = new google.maps.Geocoder()
        geocoder.geocode(
            {
                latLng: pos,
            },
            (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    $member.location = results[0].formatted_address
                    $member.lat = results[0].geometry.location.lat()
                    $member.lng = results[0].geometry.location.lng()
                } else {
                    console.error(
                        'Cannot determine address at this location.' + status
                    )
                }
            }
        )
    }
</script>

<google-places-autocomplete
    label="Address"
    placeholder="Enter address"
    types={['geocode']}
    fields={['place_id', 'geometry', 'name', 'formatted_address', 'address_components']}
    {placeChanged}
/>
