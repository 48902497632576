<svelte:options tag="widget-app" />

<script>
    import { onMount } from 'svelte'
    import { isUndefined } from 'is-what'
    import {
        showChart,
        showForm,
        isLoading,
        useDemo,
        fullReportMessage,
    } from './store/store.js'

    // const RECAPTCHA_SRC = `https://www.google.com/recaptcha/api.js?render=${process.env.RECAPTCHA_SITE_KEY}`
    const MAP_SRC = `https://maps.googleapis.com/maps/api/js?key=${process.env.GOOGLE_MAP_API_KEY}&libraries=places`

    const version = process.env.__VERSION__

    const urlParams = new URLSearchParams(location.search)
    export let use_sample_data = false,
        x_axis = '',
        y_axis = '',
        justify_content = '',
        style_key = '',
        /* undefined unicron only code */
        logo_img,
        title_text,
        summary_report_btn,
        full_report_btn,
        header_background_color,
        header_text_color,
        body_background_color_top,
        body_background_color_bottom,
        body_text_color,
        body_inputs_and_btn_color,
        btn_text_color,
        font_family,
        place_holder_business_name,
        place_holder_zipcode,
        place_holder_name,
        place_holder_email,
        place_holder_phone

    let styles = {
        enabled: false,
        logo_img: '',
        title_text:
            "Is your business visible online? Our comprehensive report will help you understand your business's online presence and how you can improve it to attract more customers. Don't miss out on potential sales — get your online visibility score checked now!",
        summary_report_btn: 'Run Visibility Score',
        full_report_btn: 'See Full Report',
        header_background_color: 'black',
        header_text_color: 'white',
        body_background_color_top: 'white',
        body_background_color_bottom: 'white',
        body_text_color: 'black',
        body_inputs_and_btn_color: 'black',
        btn_text_color: 'white',
        font_family: 'Poppins,sans-serif',
        place_holder_business_name: 'Business Name',
        place_holder_zipcode: 'ZIP code',
        place_holder_name: 'Full Name',
        place_holder_email: 'Email Address',
        place_holder_phone: 'Phone Number',
    }

    let open = urlParams.has('widget')
    if (open) {
        let setType = urlParams.get('widget')
        if (setType === 'praise' || setType === 'grievance') type.click(setType)
    }

    function handleMain(action) {
        open = action
        if (!action) {
            showForm.click(false)
            showChart.click(false)
            fullReportMessage.reset()
        }
    }

    // const isAxis = !!x_axis && !!y_axis
    $: isAxis = false
    onMount(async () => {
        console.info(
            '%c%s',
            [
                'color: #00705d',
                'background: #dcfff8',
                'padding: 10px',
                'border-radius: 2px',
                'margin: 5px 0',
            ]
                .flat()
                .join(';'),
            `Visibility Sales Widget ${version}`
        )
        try {
            isLoading.set()
            const response = await fetch(
                `https://s3.amazonaws.com/p.assets.lssdev.com/legacypartners/widget/${style_key}.json`
            )
            const data = await response.json()

            if (!!justify_content && !x_axis && !y_axis) {
                isAxis = false
            } else if (!justify_content && !!x_axis && !!y_axis) {
                isAxis = true
            } else if (
                !!data?.widget_wrapper?.vertical ||
                !!data?.widget_wrapper?.horizontal
            ) {
                isAxis = true
            }

            styles = {
                ...styles,
                ...data,
                enabled: isUndefined(data.enabled) || data.enabled,
            }
        } catch (err) {
            console.error(err)
        } finally {
            isLoading.reset()
        }

        if (use_sample_data) {
            useDemo.click()
        }
    })

    $: if (!isUndefined(logo_img)) {
        styles = {
            ...styles,
            logo_img,
        }
    }

    $: if (!isUndefined(title_text)) {
        styles = {
            ...styles,
            title_text,
        }
    }

    $: if (!isUndefined(header_background_color)) {
        styles = {
            ...styles,
            header_background_color,
        }
    }

    $: if (!isUndefined(header_text_color)) {
        styles = {
            ...styles,
            header_text_color,
        }
    }

    $: if (!isUndefined(body_background_color_top)) {
        styles = {
            ...styles,
            body_background_color_top,
        }
    }

    $: if (!isUndefined(body_background_color_bottom)) {
        styles = {
            ...styles,
            body_background_color_bottom,
        }
    }

    $: if (!isUndefined(body_text_color)) {
        styles = {
            ...styles,
            body_text_color,
        }
    }

    $: if (!isUndefined(btn_text_color)) {
        styles = {
            ...styles,
            btn_text_color,
        }
    }

    $: if (!isUndefined(body_inputs_and_btn_color)) {
        styles = {
            ...styles,
            body_inputs_and_btn_color,
        }
    }

    $: if (!isUndefined(summary_report_btn)) {
        styles = {
            ...styles,
            summary_report_btn,
        }
    }

    $: if (!isUndefined(full_report_btn)) {
        styles = {
            ...styles,
            full_report_btn,
        }
    }

    $: if (!isUndefined(font_family)) {
        styles = {
            ...styles,
            font_family,
        }
    }

    $: if (!isUndefined(place_holder_business_name)) {
        styles = {
            ...styles,
            place_holder_business_name,
        }
    }

    $: if (!isUndefined(place_holder_zipcode)) {
        styles = {
            ...styles,
            place_holder_zipcode,
        }
    }

    $: if (!isUndefined(place_holder_name)) {
        styles = {
            ...styles,
            place_holder_name,
        }
    }

    $: if (!isUndefined(place_holder_email)) {
        styles = {
            ...styles,
            place_holder_email,
        }
    }

    $: if (!isUndefined(place_holder_phone)) {
        styles = {
            ...styles,
            place_holder_phone,
        }
    }

    $: positionTop = open ? 'top:10px;' : ''
</script>

<svelte:head>
    <script async defer src={MAP_SRC}></script>
    <!-- <script async defer src={RECAPTCHA_SRC}></script> -->
</svelte:head>

{#if isAxis && (styles.enabled || use_sample_data)}
    <div
        class={`card-widget card-widget-${
            x_axis || styles?.widget_wrapper?.horizontal
        } card-widget-${y_axis || styles?.widget_wrapper?.vertical}`}
        style="--font-family: {styles.font_family}; --primary-color: {styles.body_inputs_and_btn_color}; --primary-text-color: {styles.btn_text_color};  --header-color: {styles.header_background_color}; --body-text-color: {styles.body_text_color}; --focus-ring: deepskyblue;{positionTop}"
    >
        {#if open}
            <div
                class="open-container"
                style="color: {styles.header_text_color};"
            >
                <div class="header">
                    {#if styles.logo_img}
                        <img
                            width="260"
                            loading="lazy"
                            src={styles.logo_img}
                            alt="reseller logo"
                        />
                    {/if}
                    <button
                        disabled={$isLoading}
                        class="close-btn"
                        on:click={() => handleMain(false)}
                        >CLOSE&nbsp;<close-icon /></button
                    >
                </div>
                <p>
                    {styles.title_text}
                </p>
            </div>
            {#if $showForm}
                <div
                    class="open-container-body"
                    style="background: linear-gradient({styles.body_background_color_top}, {styles.body_background_color_bottom});"
                >
                    <div class="padding">
                        <new-form
                            summary_report_btn={styles.summary_report_btn}
                            place_holder_business_name={styles.place_holder_business_name}
                            place_holder_phone={styles.place_holder_phone}
                        />
                        <!-- <div class="version">{version}</div> -->
                    </div>
                </div>
            {:else}
                <div
                    class="open-container-body"
                    style="background: linear-gradient({styles.body_background_color_top}, {styles.body_background_color_bottom});"
                >
                    <div class="padding">
                        {#if $showChart}
                            <chart-data
                                full_report_btn={styles.full_report_btn}
                            />
                        {:else}
                            <search-form
                                summary_report_btn={styles.summary_report_btn}
                                place_holder_business_name={styles.place_holder_business_name}
                                place_holder_zipcode={styles.place_holder_zipcode}
                                place_holder_name={styles.place_holder_name}
                                place_holder_email={styles.place_holder_email}
                                place_holder_phone={styles.place_holder_phone}
                                {style_key}
                            />
                            <search-list />
                        {/if}
                        <div style="display:none;" class="version">
                            {version}
                        </div>
                    </div>
                </div>
            {/if}
        {:else}
            <button class="main-btn" on:click={() => handleMain(true)}>
                <!-- <img
                width="150"
                src="https://s3.amazonaws.com/p.assets.lssdev.com/partners/45803_logo_widget1.png"
                alt="small advice logo"
            /> -->
                <div
                    style="background-color: {styles.header_background_color}; color: {styles.header_text_color};"
                >
                    <!-- <slot /> -->
                    {#if $$slots.default}
                        <slot />
                    {:else}
                        {styles?.widget_wrapper?.floatingWidgetName}
                    {/if}
                </div>
                {#if $isLoading}
                    <div class="lds-dual-ring" />
                {/if}
            </button>
            <!-- {#if complete}
            <p class="green">Voice Submitted!</p>
        {/if} -->
        {/if}
    </div>
{:else if styles.enabled || use_sample_data}
    <div
        style="justify-content: {justify_content ||
            styles?.widget_wrapper
                ?.position};  --font-family: {styles.font_family}; --primary-color: {styles.body_inputs_and_btn_color};  --primary-text-color: {styles.btn_text_color}; --header-color: {styles.header_background_color}; --body-text-color: {styles.body_text_color}; --focus-ring: deepskyblue;"
        class="static-position"
    >
        <div class="card-widget-static">
            <div
                class="open-container"
                style="background-color: {styles.header_background_color}; color: {styles.header_text_color};"
            >
                <div class="header-static">
                    {#if styles.logo_img}
                        <img
                            width="260"
                            loading="lazy"
                            src={styles.logo_img}
                            alt="reseller logo"
                        />
                    {/if}
                    <!-- {#if $isLoading}
                        <div class="lds-dual-ring-lg" />
                    {/if} -->
                </div>
                <p>
                    {styles.title_text}
                </p>
            </div>
            {#if $showForm}
                <div
                    class="open-container-body"
                    style="background: linear-gradient({styles.body_background_color_top}, {styles.body_background_color_bottom});"
                >
                    <div class="padding">
                        <new-form
                            summary_report_btn={styles.summary_report_btn}
                            place_holder_business_name={styles.place_holder_business_name}
                            place_holder_phone={styles.place_holder_phone}
                        />
                        <!-- <div class="version">{version}</div> -->
                    </div>
                </div>
            {:else}
                <div
                    class="open-container-body"
                    style="background: linear-gradient({styles.body_background_color_top}, {styles.body_background_color_bottom});"
                >
                    <div class="padding">
                        {#if $showChart}
                            <chart-data
                                full_report_btn={styles.full_report_btn}
                            />
                        {:else}
                            <search-form
                                summary_report_btn={styles.summary_report_btn}
                                place_holder_business_name={styles.place_holder_business_name}
                                place_holder_zipcode={styles.place_holder_zipcode}
                                place_holder_name={styles.place_holder_name}
                                place_holder_email={styles.place_holder_email}
                                place_holder_phone={styles.place_holder_phone}
                                {style_key}
                            />
                            <search-list />
                        {/if}
                        <!-- <div class="version">{version}</div> -->
                    </div>
                </div>
            {/if}
        </div>
    </div>
{/if}

<style>
    * {
        font-family: var(--font-family);
    }

    .static-position {
        display: flex;
        width: 100%;
        margin-top: 6px;
        margin-bottom: 6px;
    }
    .card-widget-static {
        display: inline-block;
        flex-direction: column;
        align-items: center;
        background-color: var(--header-color);
        border-radius: 8px;
        overflow-y: auto;
        border: 1px solid var(--header-color);
        /* max-width: min-content; */
    }
    .card-widget {
        position: fixed;
        display: flex;
        flex-direction: column;
        align-items: center;
        background-color: var(--header-color);
        border-radius: 8px;
        box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
        transition: 0.3s;
        margin: 6px;
        z-index: 9999;
        max-height: 100vh;
        overflow-y: auto;
        max-width: min-content;
    }

    .card-widget-top {
        top: 10px;
    }

    .card-widget-bottom {
        bottom: 10px;
        height: fit-content;
    }

    .card-widget-right {
        right: 10px;
    }

    .card-widget-left {
        left: 10px;
    }

    /* On mouse-over, add a deeper shadow */
    .card-widget:hover {
        box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
    }

    .open-container {
        background-color: var(--header-color);
        display: flex;
        flex-direction: column;
        width: 450px;
        padding: 4px 10px 10px 10px;
    }

    .open-container-body {
        display: flex;
        flex-direction: column;
        width: 100%;
    }

    .padding {
        padding: 4px 10px 10px 10px;
    }

    .header {
        display: flex;
        justify-content: space-between;
        margin-top: 6px;
    }

    .header-static {
        display: flex;
        justify-content: center;
        margin-top: 6px;
    }

    .h1 {
        font-size: 1.5em;
        align-self: center;
        margin-block-start: 0;
        margin-block-end: 0;
        font-weight: normal;
    }

    .title {
        display: flex;
        justify-content: center;
        margin-bottom: 20px;
    }

    .title > * {
        margin-left: 10px;
        margin-right: 10px;
    }

    .main-btn {
        background-color: var(--header-color);
        border: solid 3px var(--header-color);
        padding: 14px;
        text-align: center;
        text-decoration: none;
        display: flex;
        font-size: 18px;
        border-radius: 8px;
        transition-duration: 0.4s;
        width: max-content;
    }

    .main-btn:hover,
    .main-btn:focus {
        /* background-color: #d5dfea; */
        opacity: 0.9;
        border: solid 3px var(--focus-ring) !important;
        outline: solid 3px var(--focus-ring) !important;
        cursor: pointer;
    }

    .close-btn {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        font-family: inherit;
        font-weight: bold;
        max-height: 46px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 16px;
        border-radius: 4px;
    }

    .close-btn:disabled {
        cursor: not-allowed;
    }

    .close-btn:hover:enabled,
    .close-btn:focus:enabled {
        outline: solid 2px -webkit-focus-ring-color;
    }

    .green {
        color: #427873;
    }

    .lds-dual-ring-lg {
        display: flex;
        width: 68px;
        height: 36px;
        margin-top: 12px;
        margin-bottom: 12px;
        align-items: center;
    }
    .lds-dual-ring-lg:after {
        content: ' ';
        display: block;
        width: 48px;
        height: 48px;
        margin: 6px;
        border-radius: 50%;
        border: 4px solid #ffffff;
        border-color: #ffffff transparent #ffffff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    .lds-dual-ring {
        display: flex;
        width: 34px;
        height: 18px;
        margin-left: 10px;
        align-items: center;
    }
    .lds-dual-ring:after {
        content: ' ';
        display: block;
        width: 24px;
        height: 24px;
        margin: 3px;
        border-radius: 50%;
        border: 2px solid #ffffff;
        border-color: #ffffff transparent #ffffff transparent;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    .version {
        display: flex;
        justify-content: center;
        font-weight: 400;
        font-size: smaller;
        color: var(--body-text-color);
    }

    @keyframes lds-dual-rings-lg {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }

    @media (max-width: 500px) {
        .card-widget-left {
            left: 5px;
        }

        .card-widget-right {
            right: 5px;
        }

        .open-container {
            width: fit-content;
        }
        .open-container-body {
            width: 100%;
        }
        .card-widget-static {
            margin-left: 8px;
            margin-right: 8px;
            width: inherit;
        }
    }
</style>
