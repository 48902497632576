<svelte:options tag="button-component" />

<script>
    import { isLoading } from '../store/store.js'
    import { isBoolean } from 'is-what'

    export let type = 'button'
    export let styles = 'primary'
    export let disabled = false
    export let click = () => {}
    export let id = 'default-btn-id'

    async function submit() {
        await click()
    }
</script>

{#if styles === 'back-btn' || styles === 'success'}
    <button type="button" class={styles} on:click={click}
        ><arrow-left /><slot /></button
    >
    <!-- {:else if $isLoading && styles === 'secondary'}
    <button disabled={true} {type} class={styles}>
        <div><slot /></div>
        <div class="lds-dual-ring secondary-ring" />
    </button> -->
    <!-- {:else if $isLoading}
    <button disabled={true} {type} class={styles}>
        <div><slot /></div>
        <div class="lds-dual-ring primary-ring" />
    </button> -->
{:else if styles === 'secondary'}
    <button
        disabled={$isLoading || disabled}
        {type}
        class={styles}
        on:click={click}><slot /></button
    >
{:else}
    <!-- <form on:submit|preventDefault={click}> -->
    <button
        disabled={$isLoading || disabled}
        {type}
        {id}
        class={styles}
        on:click={click}
        ><slot />{#if isBoolean($isLoading) && $isLoading}<div
                class="lds-dual-ring primary-ring"
            />{:else if !isBoolean($isLoading) && $isLoading === id}<div
                class="lds-dual-ring primary-ring"
            />{/if}</button
    >
    <!-- </form> -->
{/if}

<style>
    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }

    button:disabled,
    button[disabled] {
        cursor: auto;
        display: flex;
        align-items: center;
    }

    .primary {
        background-color: var(--primary-color);
        color: var(--primary-text-color);
        padding: 16px;
        border-radius: 8px;
        border: 2px solid var(--primary-color);
    }

    .primary:disabled {
        background-color: var(--primary-color);
        cursor: wait;
    }

    .secondary {
        background-color: ghostwhite;
        color: var(--header-color);
        padding: 16px;
        text-decoration: none;
        font-size: 16px;
        border: none;
        vertical-align: top;
        max-height: 70px;
        border-radius: 8px;
        font-size: 16px;
        border: 2px solid var(--header-color);
    }

    .secondary:disabled {
        color: #ffffff;
        background-color: #94938e;
        border: 1px solid #94938e;
        cursor: wait;
    }

    .list {
        top: 4px;
        position: relative;
        background-color: ghostwhite;
        /* color: var(--header-color); */
        color: #212529;
        padding: 6px;
        text-decoration: none;
        font-size: 16px;
        border: none;
        border-radius: 8px;
        font-size: 16px;
        /* border: 2px solid var(--header-color); */
        border: 2px solid #212529;
    }

    .list:disabled {
        color: #ffffff;
        background-color: #94938e;
        border: 1px solid #94938e;
        cursor: wait;
    }

    .lds-dual-ring {
        display: flex;
        width: 34px;
        height: 18px;
        margin-left: 10px;
        align-items: center;
    }
    .lds-dual-ring:after {
        content: ' ';
        display: block;
        width: 24px;
        height: 24px;
        margin: 3px;
        border-radius: 50%;
        animation: lds-dual-ring 1.2s linear infinite;
    }

    .primary-ring:after {
        border: 2px solid var(--primary-text-color);
        border-color: var(--primary-text-color) transparent
            var(--primary-text-color) transparent;
    }

    .secondary-ring:after {
        border: 2px solid #fff;
        border-color: #fff transparent #fff transparent;
    }

    .back-btn {
        color: var(--body-text-color);
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        font-family: inherit;
        font-weight: bold;
        max-height: 46px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 16px;
        border-radius: 4px;
    }

    .back-btn:hover,
    .back-btn:focus {
        outline: solid 2px var(--focus-ring);
    }

    .success-btn {
        color: #ffffff;
        background: none;
        border: none;
        padding: 0;
        cursor: pointer;
        outline: inherit;
        font-family: inherit;
        font-weight: bold;
        max-height: 46px;
        align-items: center;
        display: flex;
        justify-content: center;
        font-size: 16px;
        border-radius: 4px;
    }

    .success-btn:hover,
    .success-btn:focus {
        outline: solid 2px var(--focus-ring);
    }

    .primary:hover:enabled,
    .primary:focus-visible:enabled,
    .secondary:hover:enabled,
    .secondary:focus-visible:enabled,
    .list:hover:enabled,
    .list:focus-visible:enabled {
        border: 2px solid var(--focus-ring);
        border-radius: 8px;
        outline: none;
    }
    @keyframes lds-dual-ring {
        0% {
            transform: rotate(0deg);
        }
        100% {
            transform: rotate(360deg);
        }
    }
</style>
