<svelte:options tag="legacy-clients-error" />

<script>
    import { onMount } from 'svelte'
    import { requestReportMessage } from '../store/store'

    onMount(() => requestReportMessage.reset())
</script>

{#if $requestReportMessage}
    <div class="error">
        {$requestReportMessage}
    </div>
{/if}

<style>
    .error {
        font-family: inherit;
        background-color: rgb(231, 76, 60);
        color: white;
        padding: 10px 20px;
        text-align: center;
        border-radius: 2px;
        margin-bottom: 8px;
    }
</style>
