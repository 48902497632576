<svelte:options tag="input-component" />

<script>
    import { onMount } from 'svelte'
    import { isNullOrUndefined } from 'is-what'
    import { createEventDispatcher } from 'svelte'
    import { get_current_component } from 'svelte/internal'
    import { isLoading } from '../store/store.js'
    import { autoFormatPhoneNumber } from './Util/util'

    const svelteDispatch = createEventDispatcher()
    const component = get_current_component()
    const dispatch = (name, detail) => {
        svelteDispatch(name, detail)
        component?.dispatchEvent(new CustomEvent(name, { detail }))
    }
    export let name = '',
        placeholder = '',
        type = 'text',
        rules = [],
        defaultvalue = null,
        pattern = null,
        maxwidth = ''

    $: maxWidth = maxwidth ? `max-width: ${maxwidth}px;` : ''

    function typeAction(node) {
        node.type = type
    }

    let value = ''

    onMount(() => {
        value = isNullOrUndefined(defaultvalue) ? value : defaultvalue
    })

    let messages = []
    function handleInput(e) {
        dispatch('value', {
            name: e.target.name,
            value: e.target.value,
        })

        if (rules.length) {
            messages = rules
                .map(rule => rule(e.target.value))
                .filter(bool => 'boolean' !== typeof bool)
        }
    }

    $: if (value && ['email', 'tel'].some(v => v === type)) {
        if ('tel' === type) {
            value = autoFormatPhoneNumber(value)
        } else {
            value = value.trim()
        }
    }
</script>

<div class="container">
    <input
        style={maxWidth}
        use:typeAction
        {placeholder}
        {name}
        {pattern}
        class="input-field"
        bind:value
        on:input={handleInput}
        disabled={!isLoading}
    />
    {#if messages.length}
        {#each messages as error}
            <div class="input-error">
                <small>{error}</small>
            </div>
        {/each}
    {/if}
</div>

<style>
    .container {
        display: grid;
        margin: 10px 0 10px 0;
    }

    .input-field {
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        line-height: inherit;
        height: auto;
        border: none;
        outline: none;
        border-radius: 6px;
        color: var(--body-text-color);
        text-transform: unset;
        text-rendering: optimizeLegibility;
        border: 2px solid var(--primary-color);
        padding: 12px 10px 12px 12px;
        width: inherit;
        /* background: initial; */
    }

    .input-field:hover,
    .input-field:focus {
        border: 2px solid var(--focus-ring);
    }

    .input-error {
        color: rgb(231, 76, 60);
        font-family: inherit;
        font-weight: 600;
        align-self: center;
        margin-top: 8px;
        padding-left: 8px;
    }

    @media (max-width: 500px) {
        .input-field {
            max-width: none !important;
        }
    }
</style>
