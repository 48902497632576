<svelte:options tag="search-form" />

<script>
    import { isNull } from 'is-what'
    import {
        search,
        isLoading,
        showChart,
        contact,
        token,
        timeToke,
        requestReportMessage,
    } from '../store/store.js'

    export let summary_report_btn = '',
        place_holder_business_name = '',
        place_holder_zipcode = '',
        place_holder_name = '',
        place_holder_email = '',
        place_holder_phone = '',
        style_key = ''

    let error = ''
    const form = {
        companyName: '',
        zipcode: '',
        name: '',
        phone: '',
        email: '',
    }

    const slugify = (str = '') =>
        str.toLowerCase().replace(/ /g, '-').replace(/\./g, '')

    const placesGet = async xApiToken => {
        const json = await fetch(
            `${process.env.API_URL}/places/${encodeURIComponent(
                `${form.companyName} ${form.zipcode}`
            )}`,
            {
                headers: {
                    accept: 'application/json',
                    'x-api-token': isNull(xApiToken) ? $token : xApiToken,
                },
                method: 'GET',
            }
        )
        const { data } = await json.json()
        if (!isNull(xApiToken)) {
            contact.set({
                name: form.name,
                email: form.email,
                phone: form.phone,
            })
        }
        if (!isNull(xApiToken)) {
            token.click(xApiToken)
        }
        search.set(data)
        isLoading.reset()
        showChart.click(false)
    }

    function handleSubmit() {
        isLoading.set()
        requestReportMessage.reset()
        error = ''
        timeToke.check()

        if (
            isNull($search) &&
            form.companyName &&
            form.zipcode &&
            form.name &&
            form.email &&
            form.phone &&
            !$timeToke.isBot
        ) {
            fetch(`${process.env.API_URL}/legacypartners/widget/auth`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    widgetKey: style_key,
                }),
            })
                .then(res => res.json())
                .then(res => {
                    if (res?.success) {
                        placesGet(res?.data?.token)
                    } else {
                        isLoading.reset()
                        error = 'Recaptcha error. Please refresh the page'
                    }
                })
                .catch(err => {
                    isLoading.reset()
                    error = 'Recaptcha error. Please refresh the page'
                })
        } else if (!isNull($search) && form.companyName && form.zipcode) {
            placesGet(null)
        } else {
            error = 'All fields are required'
            isLoading.reset()
        }
    }

    // async function doRecaptcha(sendUserData) {
    //     console.log('re-capt-cha', sendUserData, process.env.RECAPTCHA_SITE_KEY)
    //     if (sendUserData) {
    //         grecaptcha.ready(() => {
    //             grecaptcha
    //                 .execute(process.env.RECAPTCHA_SITE_KEY, {
    //                     action: 'submit',
    //                 })
    //                 .then(token => {
    //                     fetch(
    //                         `${process.env.API_URL}/legacypartners/widget/auth`,
    //                         {
    //                             method: 'POST',
    //                             headers: {
    //                                 'Content-Type': 'application/json',
    //                             },
    //                             body: JSON.stringify({
    //                                 recaptcha: token,
    //                                 widgetKey: style_key,
    //                             }),
    //                         }
    //                     )
    //                         .then(res => res.json())
    //                         .then(res => {
    //                             if (res?.success) {
    //                                 placesGet(res?.data?.token)
    //                             } else {
    //                                 isLoading.reset()
    //                                 error =
    //                                     'Recaptcha error. Please refresh the page'
    //                             }
    //                         })
    //                         .catch(err => {
    //                             isLoading.reset()
    //                             error =
    //                                 'Recaptcha error. Please refresh the page'
    //                         })
    //                 })
    //                 .catch(err => {
    //                     console.error(err)
    //                 })
    //         })
    //     } else {
    //         placesGet(null)
    //     }
    // }
</script>

{#if isNull($search)}
    <div class="container">
        <h3 class="baseline">Business Information</h3>
        <input-component
            defaultvalue={form.companyName}
            placeholder={place_holder_business_name}
            name="companyName"
            on:value={e => (form[e.detail.name] = e.detail.value)}
            rules={[e => !!e || `${place_holder_business_name} is required`]}
        />
        <input-component
            defaultvalue={form.zipcode}
            placeholder={place_holder_zipcode}
            name="zipcode"
            on:value={e => (form[e.detail.name] = e.detail.value)}
            pattern="[0-9]*"
            rules={[
                e => !!e || `Business ${place_holder_zipcode} is required`,
                e =>
                    /^((\d{5}-\d{4})|(\d{5})|([A-Z]\d[A-Z]\s\d[A-Z]\d))$/.test(
                        e
                    ) || `Invalid Business ${place_holder_zipcode} number`,
            ]}
        />
        <h3 class="baseline">Personal Information</h3>
        <input-component
            defaultvalue={form.name}
            placeholder={place_holder_name}
            name="name"
            on:value={e => (form[e.detail.name] = e.detail.value)}
            rules={[e => !!e || `Your ${place_holder_name} is required`]}
        />
        <input-component
            defaultvalue={form.email}
            placeholder={place_holder_email}
            name="email"
            type="email"
            on:value={e => (form[e.detail.name] = e.detail.value)}
            rules={[
                e => !!e || `Your ${place_holder_email} is required`,
                e =>
                    /^[a-zA-Z0-9+.-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/.test(e) ||
                    // /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(e) ||  // Doesn't except + tags
                    `Invalid ${place_holder_email}`,
            ]}
        />
        <input-component
            defaultvalue={form.phone}
            placeholder={place_holder_phone}
            name="phone"
            type="tel"
            on:value={e => (form[e.detail.name] = e.detail.value)}
            rules={[
                e => !!e || `Your ${place_holder_phone} is required`,
                e =>
                    /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                        e
                    ) || `Invalid ${place_holder_phone}`,
            ]}
        />
        <div class="btn-group">
            <button-component type="submit" click={handleSubmit}
                >{summary_report_btn}</button-component
            >
        </div>
        {#if error}
            <div class="error">
                <small>{error}</small>
            </div>
        {/if}
    </div>
{/if}

<style>
    .baseline {
        display: flex;
        justify-content: center;
        color: var(--body-text-color);
    }

    .container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin-bottom: 10px;
        width: 100%;
    }

    .container > input {
        margin: 10px 0 10px 0;
    }

    .btn-group {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: end;
        width: 100%;
    }

    .error {
        color: rgb(231, 76, 60);
        font-family: inherit;
        font-weight: 600;
        align-self: center;
        margin-bottom: 10px;
    }
</style>
