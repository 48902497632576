<svelte:options tag="donut-chart" />

<script>
    export let size = 200
    export let percent = 0
    export let label = ''
    export let value = 0

    const WHITE = '#ffffff'
    const YELLOW = 'rgb(241, 196, 15)'
    const GREEN = '#1abc9c'
    const RED = 'rgb(231,76,60)'
    const GREY = '#ebedef'

    const valueCheck = score => {
        if (score >= 75) {
            return GREEN
        } else if (score >= 35) {
            return YELLOW
        } else if (score === 0) {
            return GREY
        }
        return RED
    }

    $: viewBox = `0 0 ${size} ${size}`

    $: radius = size / 2
    $: halfCircumference = Math.PI * radius
    $: pieSize = halfCircumference * (percent / 100)
    $: dashArray = `0 ${halfCircumference - pieSize} ${pieSize}`
</script>

<svg width={size} height={size} {viewBox}>
    <circle r={radius} cx={radius} cy={radius} fill={WHITE} />
    <!-- Grey Ring -->
    <circle
        r={radius / 2}
        cx={radius}
        cy={radius}
        fill={WHITE}
        stroke={GREY}
        stroke-width={radius}
        stroke-dasharray="0 0 0"
    />
    <!-- Green Ring -->
    <circle
        class="color-ring"
        r={radius / 2}
        cx={radius}
        cy={radius}
        fill={WHITE}
        stroke={valueCheck(value)}
        stroke-width={radius}
        stroke-dasharray={dashArray}
    />
    <!-- Donut Hole -->
    <circle r={radius / 1.2} cx={radius} cy={radius} fill={WHITE} />

    <g class="donut-text" style="fill: {valueCheck(value)};">
        <text y="50%" transform="translate(0, 2)">
            <tspan x="50%" text-anchor="middle" class="donut-percent"
                >{value}%</tspan
            >
        </text>
        <text y="60%" transform="translate(0, 2)">
            <tspan x="50%" text-anchor="middle" class="donut-data"
                >{label}</tspan
            >
        </text>
    </g>
</svg>

<style>
    /* @media (min-width: 992px) {} */

    @keyframes donutfadelong {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }

    .donut-text {
        font-family: inherit;
        cursor: default;
    }

    .donut-percent {
        font-size: 1.6em;
        line-height: 1;
        transform: translateY(0.5em);
        font-weight: bold;
        animation: donutfadelong 1s;
    }

    .donut-percent:hover {
        opacity: 0.8;
    }

    .donut-data {
        font-size: 1em;
        line-height: 1;
        transform: translateY(0.5em);
        text-align: center;
        text-anchor: middle;
        fill: var(--body-text-color);
        animation: donutfadelong 1s;
        font-weight: 600;
    }

    .color-ring:hover {
        opacity: 0.8;
    }
</style>
