export const formatPhoneNumber = phoneNumberString => {
    const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
    if (match) {
        return '(' + match[1] + ') ' + match[2] + '-' + match[3]
    }
    return null
}
// TODO: make country key flags. each with its own unique if/else.
// administrative_area_level_2 === 'US'
// locality === 'CA'
export const addressComponents = array => {
    return array.reduce(
        (acc, address) => {
            address.types.forEach(v => {
                if (v === 'country') {
                    acc.country = address.short_name
                }
                if (v === 'administrative_area_level_1') {
                    acc.state = address.short_name
                }
                if (v === 'administrative_area_level_2' || v === 'locality') {
                    acc.city = address.short_name
                }
                if (v === 'postal_code') {
                    acc.zipcode = address.short_name
                }
            })
            return acc
        },
        {
            country: '',
            state: '',
            zipcode: '',
            city: '',
        }
    )
}

export const openNewTab = ({ origin, route, clientId }) => {
    const url = `https://${origin}/?page=site/clients/${route}&client_id=${clientId}`
    window.open(url, '_blank')
}

export const autoFormatPhoneNumber = phoneNumberString => {
    try {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '')
        const match = cleaned.match(/^(1|)?(\d{0,3})?(\d{0,3})?(\d{0,4})?$/)
        const intlCode = match[1] ? '+1 ' : ''
        // (***) ***-****
        return [
            intlCode,
            match[2] ? '(' : '',
            match[2],
            match[3] ? ') ' : '',
            match[3],
            match[4] ? '-' : '',
            match[4],
        ].join('')
    } catch (err) {
        return ''
    }
}
