<svelte:options tag="vertical-infinate-scroller" />

<script>
    export let list = []
</script>

<ul class="list">
    {#each list as item, i}
        <li class={`item ticker_${i + 1}`}>
            <p class="text">{item}</p>
        </li>
    {/each}
</ul>

<style>
    .list {
        position: relative;
        height: 20px;
        list-style-type: none;
        color: var(--body-text-color);
    }
    .list .item {
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        transform: translateY(10px);
        text-align: center;
        width: 100%;
        padding-left: 10px;
        padding-right: 10px;
    }

    .text {
        margin-top: 0 !important;
        font-size: 13px;
    }

    /**
    items times: appear (3) sec difference
    6: 14.5
    7: 17.5
    8: 20.5
    9: 32.5
    */
    .list .ticker_1 {
        animation: appear 32.5s 0s infinite ease;
    }
    .list .ticker_2 {
        animation: appear 32.5s 3.5s infinite ease;
    }
    .list .ticker_3 {
        animation: appear 32.5s 7s infinite ease;
    }
    .list .ticker_4 {
        animation: appear 32.5s 10.5s infinite ease;
    }
    .list .ticker_5 {
        animation: appear 32.5s 14s infinite ease;
    }
    .list .ticker_6 {
        animation: appear 32.5s 17.5s infinite ease;
    }
    .list .ticker_7 {
        animation: appear 32.5s 21s infinite ease;
    }
    .list .ticker_8 {
        animation: appear 32.5s 24.5s infinite ease;
    }
    .list .ticker_9 {
        animation: appear 32.5s 28.5s infinite ease;
    }
    @keyframes appear {
        0% {
            opacity: 0;
        }
        7% {
            opacity: 1;
            transform: translateY(0);
        }
        10% {
            opacity: 1;
            transform: translateY(0);
        }
        14% {
            opacity: 0;
            transform: translateY(-10px);
        }
        100% {
            opacity: 0;
            transform: translateY(0);
        }
    }
</style>
