<svelte:options tag="close-icon" />

<script>
    export let width = 22
</script>

<svg
    {width}
    xmlns="http://www.w3.org/2000/svg"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
>
    <path
        d="M19.25 21.5H2.75C1.48438 21.5 0.5 20.4688 0.5 19.25V2.75C0.5 1.48438 1.48438 0.5 2.75 0.5H19.25C20.4688 0.5 21.5 1.48438 21.5 2.75V19.25C21.5 20.4688 20.4688 21.5 19.25 21.5ZM18.9688 2.75H3.03125C2.84375 2.75 2.75 2.84375 2.75 3.03125V18.9688C2.75 19.1094 2.84375 19.25 3.03125 19.25H18.9688C19.1094 19.25 19.25 19.1094 19.25 18.9688V3.03125C19.25 2.84375 19.1094 2.75 18.9688 2.75ZM16.4375 17H10.0625C9.54688 17 9.26562 16.3906 9.64062 16.0156L11.8906 13.7656L5.14062 7.01562C4.90625 6.78125 4.90625 6.40625 5.14062 6.21875L6.21875 5.14062C6.40625 4.90625 6.78125 4.90625 7.01562 5.14062L13.7656 11.8906L16.0156 9.64062C16.3906 9.3125 17 9.54688 17 10.0625V16.4375C17 16.7188 16.7188 17 16.4375 17Z"
        fill="currentColor"
    />
</svg>
