<svelte:options tag="progress-bar" />

<script>
    export let value = 0
    export let caption = ''
    export let isComplete = false

    let calcValue = 0
    const maxValue = 100
    const interval = setInterval(() => {
        if (calcValue < maxValue) {
            calcValue += 1
        }

        if (calcValue === maxValue - 1 && !isComplete) {
            clearInterval(interval)
        }
    }, 1800)

    $: if (value > calcValue && calcValue !== maxValue) {
        calcValue = value
    }

    $: if (isComplete) {
        calcValue = maxValue
    }
</script>

{#if calcValue !== 0}
    <div>
        {#if caption}
            <div class="flex">
                <div class="progress-info">
                <div class="requestTitle">
                    {caption}
                </div>
                <div class="dotElastic" />
            </div>
            </div>
        {/if}
        <div class="progressContainer" data-percentage={calcValue}>
            <div class="progressRequest" style="width:{calcValue}%" />
            <div class="percentage" style="left:{calcValue}%">
                {calcValue + '%'}
            </div>
        </div>
        <vertical-infinate-scroller
            list={[
                'We are checking Google',
                'We are checking Bing',
                'We are checking Yelp',
                'We are checking your business accuracy',
                'We are checking important directories',
                'We are calculating your online Visibility Score',
                'We are checking your Name, Address and Phone number for accuracy',
                'Don\'t have time to wait for your online visibility report to run?',
                'The full report will be emailed to you in a few minutes'
            ]}
        />
    </div>
{/if}

<style>
    .flex {
        display: flex;
        margin-top: 10px;
    }

    .progress-info {
        display: flex;
        margin-bottom: 4px;
    }
    /**
 * ==============================================
 * Progess Bar Request
 * ==============================================
 */
    .progressContainer,
    .progressRequest {
        background-color: rgba(0, 0, 0, 0.15);
        border-radius: 5px;
        position: relative;
        height: 12px;
        margin-bottom: 48px;
        /* width: 300px; */
    }

    .requestTitle {
        font: 300 Roboto;
        font-size: 15px;
        color: var(--body-text-color);
        font-weight: 600;
        display: flex;
        position: relative;
    }

    .progressRequest {
        background-color: rgb(26, 188, 156);
        width: 0;
        transition: width 0.4s linear;
    }

    .percentage {
        background-color: rgb(26, 188, 156);
        border-radius: 5px;
        /* box-shadow: 0 4px 5px rgb(0, 0, 0, 0.2); */
        color: #ffffff;
        font-weight: 600;
        font-size: 15px;
        padding: 4px;
        position: absolute;
        top: 20px;
        left: 0;
        transform: translateX(-50%);
        /* width: 40px; */
        text-align: center;
        transition: left 0.4s linear;
    }

    .percentage::after {
        background-color: rgb(26, 188, 156);
        content: '';
        position: absolute;
        top: -5px;
        left: 50%;
        transform: translateX(-50%) rotate(45deg);
        height: 10px;
        width: 10px;
        z-index: -1;
    }

    /**
 * ==============================================
 * Dot Elastic
 * ==============================================
 */
    .dotElastic {
        left: 22px;
        top: 8px;
        position: relative;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: rgb(26, 188, 156);
        color: rgb(26, 188, 156);
        animation: dot-elastic 1s infinite linear;
    }

    .dotElastic::before,
    .dotElastic::after {
        content: '';
        display: inline-block;
        position: absolute;
        top: 0;
    }

    .dotElastic::before {
        left: -15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: rgb(26, 188, 156);
        color: rgb(26, 188, 156);
        animation: dot-elastic-before 1s infinite linear;
    }

    .dotElastic::after {
        left: 15px;
        width: 10px;
        height: 10px;
        border-radius: 5px;
        background-color: rgb(26, 188, 156);
        color: rgb(26, 188, 156);
        animation: dot-elastic-after 1s infinite linear;
    }

    @keyframes dot-elastic-before {
        0% {
            transform: scale(1, 1);
        }

        25% {
            transform: scale(1, 1.5);
        }

        50% {
            transform: scale(1, 0.67);
        }

        75% {
            transform: scale(1, 1);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    @keyframes dot-elastic {
        0% {
            transform: scale(1, 1);
        }

        25% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1, 1.5);
        }

        75% {
            transform: scale(1, 1);
        }

        100% {
            transform: scale(1, 1);
        }
    }

    @keyframes dot-elastic-after {
        0% {
            transform: scale(1, 1);
        }

        25% {
            transform: scale(1, 1);
        }

        50% {
            transform: scale(1, 0.67);
        }

        75% {
            transform: scale(1, 1.5);
        }

        100% {
            transform: scale(1, 1);
        }
    }
</style>
