<svelte:options tag="modal-component" />

<script>
    export let show = false
    export let position = 0
    export let text = ''
</script>

<dialog open={show} class="position" style="margin-top:{position}px">
    <p class="text">{text}</p>
</dialog>

<style>
    .position {
        background-color: #888;
        max-width: 375px;
        opacity: 0.9;
        border-radius: 8px;
        border: none;
        cursor: pointer;
    }

    .text {
        color: #ffffff;
        text-align: justify;
    }
</style>
