<svelte:options tag="google-places-autocomplete" />

<script>
    import { isNull, isEmptyObject } from 'is-what'
    import { onMount } from 'svelte'
    import { missingSearchListingData } from '../../store/store'
    import {
        placeChanged,
        ready,
        blur as mapBlur,
        clear as mapClear,
        member,
    } from './stores.js'

    const v = !isEmptyObject($missingSearchListingData)
        ? `${$missingSearchListingData.street}, ${$missingSearchListingData.city}, ${$missingSearchListingData.state} ${$missingSearchListingData.zipcode}, ${$missingSearchListingData.country}`
        : null

    export let id = `gm-autocomplete-${Math.random()
        .toString(36)
        .replace(/[^a-z]+/g, '')
        .substr(0, 5)}`
    export let ariaLabel = 'location'
    export let placeholder = 'Location'
    export let value = null
    export let viewValue = v
    export let viewLabel = 'formatted_address'
    export let fields = ['geometry', viewLabel]
    export let types = ['(regions)']
    export let options = {}

    // let y
    // let elem
    // let rect

    let search = ''
    let autocomplete
    let currentPlace
    let disabled = true

    export function clear() {
        value = null
        viewValue = null
        currentPlace = null

        mapClear.set(true)
    }

    function dropdownVisible() {
        return (
            document.querySelectorAll('.pac-container')[0].offsetParent !== null
        )
    }

    function autocompleteKeydown(e) {
        if (e.keyCode === 13 && dropdownVisible()) {
            e.preventDefault()
        }
    }

    function blur() {
        mapBlur.set(true)
        if (viewValue !== (currentPlace && currentPlace[viewLabel])) {
            clear()
        }
    }

    function initialise() {
        const google = window.google

        autocomplete = new google.maps.places.Autocomplete(
            search,
            Object.assign(
                {
                    fields,
                    types,
                },
                options
            )
        )

        disabled = false

        autocomplete.addListener('place_changed', () => {
            const place = autocomplete.getPlace()
            if (place.geometry) {
                viewValue = place[viewLabel]
                value = viewValue
                currentPlace = place
                placeChanged.set({ place, selectedPrediction: search.value })
            }
        })

        ready.set(true)
    }

    onMount(() => {
        initialise()
    })

    // function appendIt() {
    // 	const source = document.getElementsByClassName('pac-container pac-logo')[0]
    // 	document.getElementById('some-bs-yo').appendChild(source)

    //     elem = document.getElementById(id);
    //     console.log(elem)
    //     rect = elem.getBoundingClientRect();
    // }
    $: if (!viewValue && !isNull(viewValue)) {
        member.reset()
    }
</script>

<!-- {#if label}
	<label for={id}>{label}</label>
{/if} -->
<input
    {id}
    aria-label={ariaLabel}
    style="z-index: 9999;"
    class="input-field"
    {placeholder}
    bind:this={search}
    type="search"
    {disabled}
    bind:value={viewValue}
    on:blur={blur}
    on:keydown={autocompleteKeydown}
/>
<!-- <div id="some-bs-yo"/> -->

<!-- <svelte:window bind:scrollY={y} /> -->
<style>
    /* label {
		font-weight: 800;
	} */

    .input-field {
        font-family: inherit;
        font-size: 14px;
        font-weight: 400;
        line-height: inherit;
        height: fit-content;
        border: none;
        outline: none;
        border-radius: 6px;
        color: var(--body-text-color);
        text-transform: unset;
        text-rendering: optimizeLegibility;
        border: 2px solid var(--primary-color);
        padding: 12px 10px 12px 12px;
        width: 100%;
    }

    .input-field:hover,
    .input-field:focus {
        border: 2px solid var(--focus-ring);
    }
</style>
