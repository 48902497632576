<svelte:options tag="chart-data" />

<script>
    import { onMount } from 'svelte'
    import { tweened } from 'svelte/motion'
    import { isUndefined } from 'is-what'
    import {
        showChart,
        isLoading,
        token,
        clientId,
        requestReportMessage,
        fullReportMessage,
        search,
    } from '../store/store.js'
    import { CHART_DATA } from '../store/mock-data'

    export let full_report_btn = ''

    const COUNT_TOTAL = 10
    let chartData = 0
    let progressBar = 1
    let isComplete = false
    let link = ''

    let issues = 0
    const issuesFound = (num1, num2) => {
        const n1 = isUndefined(num1) ? 0 : num1
        const n2 = isUndefined(num2) ? 0 : num2
        return Math.abs(n1 - n2)
    }

    let show = false
    function handleMouse(e) {
        show = e.type === 'mouseover'
        // const rect = e.currentTarget.getBoundingClientRect()
        // const x = e.clientX - rect.x
        // const y = e.clientY - rect.y
        // const logic = x > -1 && x < 450 && y > -1 && y < 276
        // console.log(e.type, `x: ${x}, y: ${y}`, logic)
        // show = logic
    }

    const legacyclients = `${process.env.API_URL}/legacyclients`
    // let chartData =
    //     CHART_DATA?.data?.overview?.baselineOverview?.visibilityScore
    const store = tweened(0, { duration: 1000 })
    $: store.set(chartData)

    const legacyclientsReport = id =>
        // `${process.env.API_URL}/legacyscoredata?client=${id}&limit=1&sort=id DESC`
        `${process.env.API_URL}/legacyclients/${id}/report`

    onMount(async () => {
        let count = 0
        const json = await fetch(`${legacyclients}/${$clientId}/url`, {
            headers: {
                accept: 'application/json',
                'x-api-token': $token,
            },
        })
        const url = await json.json()

        link = url?.data
        runTimer(1000)
        async function timedOut() {
            const json = await fetch(legacyclientsReport($clientId), {
                headers: {
                    'x-api-token': $token,
                },
            })
            const response = await json.json()
            if (
                (response?.data?.overview?.baselineOverview.localsTotal >= 3 &&
                    response?.data?.overview?.baselineOverview
                        .directoriesTotal >= 43) ||
                count === COUNT_TOTAL
            ) {
                chartData =
                    response?.data?.overview?.baselineOverview?.visibilityScore
                progressBar = chartData
                isComplete = true

                issues =
                    issuesFound(
                        response?.data?.overview?.baselineOverview
                            ?.directoriesTotal,
                        response?.data?.overview?.baselineOverview
                            ?.directoriesFound
                    ) +
                    issuesFound(
                        response?.data?.overview?.baselineOverview?.localsTotal,
                        response?.data?.overview?.baselineOverview?.localsFound
                    )

                fullReportMessage.click([
                    `We found (${issues}) issues.`,
                    'The Full Report will be emailed within a few minutes.',
                    'Your final online visibility score is subject to change as we gather more of your online data.',
                ])
                isLoading.reset()
            } else if (count < COUNT_TOTAL) {
                if (
                    !isUndefined(
                        response?.data?.overview?.baselineOverview
                            ?.visibilityScore
                    )
                ) {
                    chartData =
                        response?.data?.overview?.baselineOverview
                            ?.visibilityScore
                    progressBar = chartData
                    runTimer(10000)
                } else {
                    chartData = 0
                    progressBar = 1
                    requestReportMessage.set(
                        'Error occured with Request. Please refresh your browser and try again.'
                    )
                    isLoading.reset()
                }
            }
        }

        function runTimer(time) {
            count++
            setTimeout(timedOut, time)
        }
    })
</script>

{#if $isLoading}
    <div class="progress">
        <progress-bar
            value={progressBar}
            caption="This report may take a few minutes to complete"
            {isComplete}
        />
    </div>
{:else}<button-component
        styles="back-btn"
        click={() => {
            showChart.click(false)
            fullReportMessage.reset()
        }}>Back to Selections</button-component
    >
{/if}
<modal-component
    {show}
    position={102}
    text="The visibility score calculates your business's online visibility based on how frequently and accurately your business name, address, and phone number appear on the most important web profiles and platforms such as Google, Bing, Yelp, and other top-performing websites."
/>

<div class="header">
    <h2 class="baseline">Online Visibility Score Summary</h2>
    <info-icon
        on:mouseout={handleMouse}
        on:blur={handleMouse}
        on:mouseover={handleMouse}
        on:focus={handleMouse}
    />
</div>
<div class="container">
    <div class="chart-container">
        <!-- <p>Visibility Score</p> -->
        <!-- <donut-chart
            size={200}
            percent={$store1}
            label="Visibility Score"
            value={visibilityScore}
        /> -->
        <donut-chart
            size={200}
            percent={$store}
            label="Visibility Score"
            value={chartData}
        />
    </div>
    <!-- <div class="chart-container">
        <donut-chart
            size={200}
            percent={$store2}
            label="NAP Score"
            value={napScore}
        />
    </div> -->
</div>

<legacy-clients-error />
{#if $fullReportMessage.length}
    <div class="success">
        <ul class="issues">
            {#each $fullReportMessage as message}
                <li>{message}</li>
            {/each}
        </ul>
        <div class="success-button">
            <button-component
                styles="success-btn"
                click={() => {
                    search.reset()
                    showChart.click(false)
                    fullReportMessage.reset()
                }}
                ><arrow-left />Search for an additional business?</button-component
            >
        </div>
    </div>
{/if}
<div class="btn-group">
    {#if $isLoading}
        <button-component type="submit" disabled={true} click={() => {}}
            >{full_report_btn}</button-component
        >
    {:else}
        <!-- TODO: rm &new=true once old baseline/progress report views are deprecated -->
        <button-component
            type="submit"
            click={() => window.open(`${link}&new=true`, '_blank')}
            >{full_report_btn}</button-component
        >
    {/if}
</div>

<style>
    .header {
        display: flex;
        flex-direction: row;
        justify-content: center;
    }
    .baseline {
        display: flex;
        justify-content: center;
        color: var(--body-text-color);
    }

    .progress {
        max-width: 430px;
        margin: 0 18px;
    }
    .container {
        display: flex;
        align-items: center;
        overflow-y: auto;
        margin-bottom: 10px;
        justify-content: center;
    }
    .chart-container {
        flex-direction: column;
        display: flex;
        margin: 12px;
        align-items: center;
    }

    .btn-group {
        display: flex;
        justify-content: space-around;
        margin-bottom: 30px;
        align-items: end;
        width: 100%;
    }

    .success {
        font-family: inherit;
        background-color: rgb(26, 188, 156);
        color: white;
        padding: 10px 20px;
        text-align: center;
        border-radius: 2px;
        margin-bottom: 12px;
    }

    .success-button {
        display: flex;
        justify-content: center;
    }

    .issues {
        align-items: start;
        display: inline-flex;
        flex-direction: column;
    }

    .issues > li {
        text-align: left;
    }
</style>
