<svelte:options tag="search-list" />

<script>
    import { onMount } from 'svelte'
    import {
        search,
        showChart,
        showForm,
        isLoading,
        contact,
        token,
        clientId,
        requestReportMessage,
        fullReportMessage,
        missingSearchListingData
    } from '../store/store.js'
    import { isNull } from 'is-what'
    import { formatPhoneNumber } from '../lib/Util/util'

    // let scroll
    // $: if ($search?.length) {
    //     if (scroll) {
    //         setTimeout(() => {
    //             scroll.scrollIntoView({
    //                 behavior: 'smooth',
    //             })
    //         }, 100)
    //     }
    // }

    onMount(() => missingSearchListingData.reset())

    async function submit(index) {
        // isLoading.set()
        isLoading.target(index+1)
        requestReportMessage.reset()
        fullReportMessage.reset()

        const s = $search[index]
        const body = {
            name: s.business_name,
            phone: s.phone,
            website: s.website,
            street: s.street,
            city: s.city,
            state: s.state,
            zipcode: s.zipcode,
            country: s.country,
            suite: s.suite,
            notes: `name: ${$contact.name}\nemail: ${
                $contact.email
            }\nphone: ${formatPhoneNumber($contact.phone)}`,
        }

        try {
            const post = await fetch(`${process.env.API_URL}/legacyclients`, {
                headers: {
                    accept: 'application/json',
                    'x-api-token': $token,
                },
                method: 'POST',
                body: JSON.stringify(body),
            })
            const { data, status } = await post.json()
            if (status !== 200 && status !== 201) {
                const str = data[0]?.message
                const message = str.includes('already')
                    ? 'Cannot scan. This business is already a listings management client.'
                    : str
                requestReportMessage.set(message)
                isLoading.reset()
            } else {
                await clientId.set(data?.id)
                await showChart.click(true)
                await showForm.click(false)
            }
        } catch (error) {
            requestReportMessage.set(error)
            isLoading.reset()
        }
    }

    function missingData(obj) {
        missingSearchListingData.set(obj)
        showForm.click(true)
    }
</script>

{#if !isNull($search)}
    <button-component
        styles="back-btn"
        click={() => {
            search.reset()
        }}>Back to Home</button-component
    >
{/if}
{#if !isNull($search) && $search?.length}
    <div class="container">
        <h2 class="baseline">Select Your Business</h2>
        <!-- <div class="container" bind:this={scroll}> -->
        <legacy-clients-error />
        <div class="list">
            <!-- <table>
                    <tr>
                        <th>Name</th>
                        <th>Street</th>
                        <th>City/State</th>
                        <th>Zip</th>
                    </tr>
                    {#each $search as { business_name, street, zipcode, city, state }, i}
                        <tr class="row" on:click={() => submit(i)}>
                            <td>{business_name}</td>
                            <td>{street}</td>
                            <td>{city}, {state}</td>
                            <td>{zipcode}</td>
                        </tr>
                    {/each}
                </table> -->
            {#each $search as item, i}
                <div class="row">
                    <div>
                        <div class="row-name">{item.business_name}</div>
                        <div class="row-address">
                            {item.street}, {item.city}, {item.state}
                        </div>
                    </div>
                    {#if item.phone && item.street}
                        <button-component styles="list" id={i+1} click={() => submit(i)}
                            >Me!</button-component
                        >
                    {:else}
                        <button-component
                            styles="list"
                            id={i+1}
                            click={() => missingData(item)}
                            >Me! (Update info)</button-component
                        >
                    {/if}
                </div>
            {/each}
        </div>
    </div>
    <div class="btn-group">
        <!-- <button-component styles="secondary" click={() => showForm.click(true)}
            >My business is not listed</button-component
        > -->
        <button-component click={() => showForm.click(true)}
            >My business is not listed</button-component
        >
    </div>
{:else if !isNull($search) && !$search?.length}
    <div class="container">
        <p class="baseline">
            No Listings Found. Click 'My business is not listed' below.
        </p>
        <div class="btn-group">
            <button-component
                click={() => showForm.click(true)}
                >My business is not listed</button-component
            >
            <!-- <button-component
                styles="secondary"
                click={() => showForm.click(true)}
                >My business is not listed</button-component
            > -->
        </div>
    </div>
{/if}

<style>
    .baseline {
        display: flex;
        justify-content: center;
        color: var(--body-text-color);
    }

    .container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        margin-bottom: 10px;
    }

    .mask {
        background-color: #212529;
        opacity: 0.5;
        cursor: wait;
        border: 1px solid #d7dadd;
        border-radius: 2px;
    }

    /* table {
        border-collapse: collapse;
        width: 100%;
        margin-bottom: 2px;
        background-color: #ffffff;
    }

    td,
    th {
        border: 1px solid #d7dadd;
        text-align: left;
        padding: 8px;
    }

    tr:nth-child(even) {
        background-color: #c6cacd;
    }

    .row:hover {
        opacity: 0.8;
        cursor: pointer;
        outline: 2px solid var(--focus-ring);
    } */

    .list {
        border: 1px solid #d7dadd;
        border-radius: 4px;
    }

    .row {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 4px;
        color: black;
    }

    .row:nth-child(odd) {
        background-color: #ffffff;
    }

    .row:nth-child(even) {
        background-color: #f5f5f5;
    }

    .row-name {
        font-weight: bold;
        margin-bottom: 4px;
    }

    .row-address {
        font-size: 14px;
    }

    .btn-group {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: end;
        width: 100%;
    }

    @media (max-width: 500px) {
        .input-field {
            max-width: 280px;
        }
    }
</style>
