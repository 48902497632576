<svelte:options tag="new-form" />

<script>
    import { onMount } from 'svelte'
    import { isUndefined, isEmptyObject } from 'is-what'
    import {
        showChart,
        search,
        showForm,
        contact,
        token,
        requestReportMessage,
        fullReportMessage,
        isLoading,
        clientId,
        missingSearchListingData,
    } from '../store/store.js'
    // import { STATES } from '../lib/states'
    // import { COUNTRIES } from '../lib/countries'
    import { formatPhoneNumber } from '../lib/Util/util'
    import { member } from '../lib/Map/stores'

    export let summary_report_btn = '',
        place_holder_business_name = '',
        place_holder_phone = ''

    const form = {
        name: '',
        suite: '',
        phone: '',
        website: '',
    }

    const slugify = (str = '') =>
        str.toLowerCase().replace(/ /g, '-').replace(/\./g, '')

    onMount(() => {
        if (!$search.length) {
            search.reset()
        }
    })

    $: if (!isEmptyObject($missingSearchListingData)) {
        form.name = $missingSearchListingData?.business_name || ''
        form.suite = $missingSearchListingData?.suite || ''
        form.phone = $missingSearchListingData?.phone || ''
        form.website = $missingSearchListingData?.website || ''
    }

    async function handleSubmit() {
        if (
            form.name &&
            form.phone &&
            (!isEmptyObject($missingSearchListingData) ||
                !isUndefined($member?.info))
        ) {
            isLoading.set()
            requestReportMessage.reset()
            fullReportMessage.reset()

            const body = {
                name: form.name,
                phone: form.phone,
                website: form.website,
                street:
                    $missingSearchListingData?.street || $member?.info?.street,
                city: $missingSearchListingData?.city || $member?.info?.city,
                state: $missingSearchListingData?.state || $member?.info?.state,
                zipcode:
                    $missingSearchListingData?.zipcode ||
                    $member?.info?.zipcode,
                country:
                    $missingSearchListingData?.country ||
                    $member?.info?.country,
                suite: form.suite,
                notes: `name: ${$contact.name}\nemail: ${
                    $contact.email
                }\nphone: ${formatPhoneNumber($contact.phone)}`,
            }

            try {
                const post = await fetch(
                    `${process.env.API_URL}/legacyclients`,
                    {
                        headers: {
                            accept: 'application/json',
                            'x-api-token': $token,
                        },
                        method: 'POST',
                        body: JSON.stringify(body),
                    }
                )
                const { data, status } = await post.json()
                if (status !== 200 && status !== 201) {
                    const str = data[0]?.message
                    const message = str.includes('already')
                        ? 'Cannot scan. This business is already a listings management client.'
                        : str
                    requestReportMessage.set(message)
                    isLoading.reset()
                } else {
                    await clientId.set(data?.id)
                    await showChart.click(true)
                    await showForm.click(false)
                }
            } catch (error) {
                requestReportMessage.set(error)
                isLoading.reset()
            }
        }
    }
</script>

<button-component styles="back-btn" click={() => showForm.click(false)}
    >Back to Search</button-component
>
<br />
<div class="container">
    <h3 class="baseline">Business Information</h3>
    <input-component
        defaultvalue={form.name}
        placeholder={place_holder_business_name}
        name="name"
        on:value={e => (form[e.detail.name] = e.detail.value)}
        rules={[e => !!e || `${place_holder_business_name} is required`]}
    />
    <div class="side-by-side">
        <div class="full-width right">
            <input-component
                defaultvalue={form.phone}
                placeholder={place_holder_phone}
                name="phone"
                type="tel"
                on:value={e => (form[e.detail.name] = e.detail.value)}
                rules={[
                    e => !!e || `Business ${place_holder_phone} is required`,
                    e =>
                        /^\s*(?:\+?(\d{1,3}))?[-. (]*(\d{3})[-. )]*(\d{3})[-. ]*(\d{4})(?: *x(\d+))?\s*$/.test(
                            e
                        ) || `Invalid ${place_holder_phone}`,
                ]}
            />
        </div>
        <div class="full-width left">
            <input-component
                defaultvalue={form.website}
                placeholder="Website"
                name="website"
                pattern="[0-9]*"
                on:value={e => (form[e.detail.name] = e.detail.value)}
            />
        </div>
    </div>

    <div class="side-by-side">
        <div
            class="full-width right"
            style="margin-top: 10px; margin-bottom: 10px;"
        >
            <location-input style="width: 100%;" />
        </div>
        <div class="left">
            <input-component
                defaultvalue={form.suite}
                placeholder="Suite"
                name="suite"
                pattern="[0-9]*"
                maxwidth="60"
                on:value={e => (form[e.detail.name] = e.detail.value)}
            />
        </div>
    </div>
</div>
<legacy-clients-error />
<div class="btn-group">
    <button-component type="submit" click={handleSubmit}
        >{summary_report_btn}</button-component
    >
</div>

<style>
    .container {
        display: flex;
        flex-direction: column;
        overflow-y: auto;
        width: 100%;
    }

    .baseline {
        display: flex;
        justify-content: center;
        color: var(--body-text-color);
    }

    .side-by-side {
        display: flex;
        justify-content: space-between;
    }

    .left {
        margin-left: 4px;
    }

    .right {
        margin-right: 4px;
    }

    .full-width {
        width: 100%;
    }

    .btn-group {
        display: flex;
        justify-content: space-around;
        margin-top: 20px;
        margin-bottom: 20px;
        align-items: end;
        width: 100%;
    }

    @media (max-width: 500px) {
        .container {
            display: flex;
            flex-direction: column;
        }

        .side-by-side {
            flex-direction: column;
        }

        .left {
            margin-left: 0;
        }

        .right {
            margin-right: 0;
        }
    }
</style>
